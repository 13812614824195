import React from 'react'
import styled from 'styled-components'
import media from 'utils/media-queries'
import { color } from 'styles/theme'

const StyledImgPortrait = styled.div`
  display: block;
  height: ${props =>
    props.aspectRatio ? 28 / props.aspectRatio + 'vw' : '43vw'};
  background: ${color.coolGrey200};
  background-size: cover;
  background-position: center center;
  background-image: url(${props => props.img});
  ${media.sm`
    height: 129vw;
    height: ${props =>
      props.aspectRatio ? 88 / props.aspectRatio + 'vw' : '43vw'};
  `}
`
const ImgPortrait = props => {
  return (
    <StyledImgPortrait img={props.img} aspectRatio={props.aspectRatio}>
      {props.children}
    </StyledImgPortrait>
  )
}

export default ImgPortrait
