import React from 'react'

import Article from 'components/article'

import Image1 from '../img/articles/hytide/1.jpg'
import Image2 from '../img/articles/hytide/2.jpg'
import Image3 from '../img/articles/hytide/3.jpg'
import Image4 from '../img/articles/hytide/4.jpg'
import Image5 from '../img/articles/hytide/5.jpg'

import TwoImageWrapper from 'components/twoImageWrapper'
import ThreeImageWrapper from 'components/threeImageWrapper'
import ImgLandscape from 'components/imgLandscape'
import ImgPortrait from 'components/imgPortrait'
import Seo from 'components/seo'

const Hytide = () => {
  return (
    <Article
      title="Hytide"
      description="Electronic music & art events"
      from="2012"
      to="2023"
      roles={['Founder', 'Managing Director', 'Experience Designer']}
      text={
        <>
          <p>
            Hytide hosts the annual music and art festivals "Schall im Schilf"
            and "Back to the Woods" north of Munich with about 10.000 visitors
            per event.
          </p>
          <p>
            The vision of hytide is to create unique light and sound experiences
            in beautiful open air locations. With a group of highly talented,
            like-minded people, a world of dynamic and futuristic constructions
            is created twice a year for a 13-hour show.
          </p>
        </>
      }
      more={
        <>
          <Seo title="Hytide" />
          <TwoImageWrapper>
            <ImgLandscape img={Image1} />
            <ImgLandscape img={Image2} />
          </TwoImageWrapper>
          <ThreeImageWrapper>
            <ImgPortrait img={Image3} />
            <ImgPortrait img={Image4} />
            <ImgPortrait img={Image5} />
          </ThreeImageWrapper>
        </>
      }
    />
  )
}

export default Hytide
