import React from 'react'
import styled from 'styled-components'
import media from 'utils/media-queries'

const StyledThreeImageWrapper = styled.div`
display: grid;
grid-template-columns: auto auto auto;
align-items: center;
flex-direction: column;
margin: 0 6vw 4vw 6vw;
grid-gap: 2vw;
${media.sm`
  grid-template-columns: auto;
`}
`

const ThreeImageWrapper = props => {
  return <StyledThreeImageWrapper>{props.children}</StyledThreeImageWrapper>
}

export default ThreeImageWrapper
